import { transportation } from '@/axios'

export default {
  addVehicle (payload) {
    return transportation().post('vehicles/company', {
      featuredImage: payload.featuredImage,
      images: payload.images,
      vehicleTypeId: payload.vehicleTypeId,
      vehicleMakeId: payload.vehicleMakeId,
      serialNumber: payload.serialNumber,
      vin: payload.vin,
      model: payload.model,
      plateNumber: payload.plateNumber,
      year: +payload.year
    })
  },
  getVehiclesMake (payload) {
    return transportation().get('vehicle-makes', payload)
  },
  getVehiclesType (payload) {
    return transportation().get('allowed-vehicle-types', payload)
  }
}
